import React from 'react'
import { Section } from './style'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'
interface INaoCorrentistaProps {
  isOpenModal: boolean;
  openFormModal: (data: boolean) => void;
  setDataLayer: (state: IDataLayerParams) => void;
}

const NaoCorrentista = ({ isOpenModal, openFormModal, setDataLayer }: INaoCorrentistaProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_02',
    section_name: 'Cliente Inter tem mais vantagens e aproveita o adiantamento do FGTS sem ter que mexer no bolso. Abra sua conta gratuita!',
    element_action: 'click button',
    element_name: 'Abra sua conta digital',
  }

  const handleOpenFormModal = () => {
    openFormModal(!isOpenModal)
  }

  return (
    <Section>
      <ImageWebp
        className='d-block d-md-none'
        pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-mobile-fgts/image.webp'
        altDescription=''
        arrayNumbers={[ 360, 768, 1024, 1440 ]}
      />
      <div className='container'>
        <div className='row justify-content-end align-items-center'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-20 lh-25 fs-xl-28 lh-xl-32 text-grayscale--500 pb-2 pt-3 fw-500'>Cliente Inter tem mais vantagens e aproveita o adiantamento do FGTS sem ter que mexer no bolso. Abra sua conta gratuita!</h2>
            <button
              onClick={() => {
                handleOpenFormModal()
                sendDatalayerEvent(dataLayer)
                setDataLayer(dataLayer)
              }}
              className='btn btn-orange--extra btn--lg fs-14 fw-600 rounded-2 mx-auto text-white text-none mt-2 w-100'
            >
              Abra sua conta digital
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default NaoCorrentista
