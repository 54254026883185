import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: ${grayscale[100]};

  .margin-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .react-multi-carousel-dot {
    button {
      background: ${orange.extra};
    }
  }
`

export const CardCarousel = styled.div`
  padding: 24px;
  background-color: white;
  border-radius: 12px;
  min-height: 268px;
  margin-top: 24px;
  width: 330px;

  @media ${device.tablet} {
    min-height: 150px;
    width: 200px;
    margin-top: 0;
    line-height: 12px;
  }

  @media ${device.desktopLG} {
    min-height: 217px;
    width: 290px;
    margin-top: 0;
    line-height: 12px;
  }

  @media ${device.desktopXL} {
    min-height: 260px;
    width: 350px;
    margin-top: 0;
    line-height: 22px;
  }
`

export const WrapperImage = styled.div`
  display: flex;
  justify-content: center;
`
