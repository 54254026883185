import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'

const GaleraAbrindoContaMD = 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-768-fgts/image.webp'
const GaleraAbrindoContaLG = 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-1024-fgts/image.webp'
const GaleraAbrindoContaXL = 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-1440-fgts/image.webp'

export const Section = styled.section`
  min-height: 355px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;

  @media ${device.tablet} {
    background: white url(${GaleraAbrindoContaMD}) no-repeat center left;
    background-size: auto 100%;
    min-height: 265px;
    flex-direction: row;
    padding-top: 0;
  }

  @media ${device.desktopLG} {
    background: white url(${GaleraAbrindoContaLG}) no-repeat center left;
    background-size: auto 100%;
    min-height: 300px;
  }

  @media ${device.desktopXL} {
    background: white url(${GaleraAbrindoContaXL}) no-repeat center left;
    background-size: auto 100%;
    min-height: 450px;
  }

  button {
    font-size: 14px !important;
    font-weight: 700 !important;
    max-width: 100%;
  }
`
