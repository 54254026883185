import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { grayscale } from 'src/styles/colors'
import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'
import { ComoAdiantarSection, CardData } from './style'
import ImageWebp from 'src/components/ImageWebp'

const DataWithIcon = [
  {
    icon: 'user-account',
    description: 'Ser maior de 18 anos e ter optado pelo saque-aniversário;',
  },
  {
    icon: 'wallet',
    description: 'Trabalhar de carteira assinada ou ter saldo FGTS em uma conta ativa ou inativa;',
  },
  {
    icon: 'like-outline',
    description: 'Ter autorizado o Inter a acessar as informações do seu FGTS;',
  },
  {
    icon: 'smartphones',
    description: 'Ser correntista do Inter. ',
  },
]

type dataProps = {
  icon: string;
  description: string;
}

const ComoAdiantar = () => {
  return (
    <ComoAdiantarSection className='bg-white d-flex position-relative align-items-center'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-5 pb-4 pb-md-0 d-block d-md-none'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/mobile-fgts-dobra4/image.webp'
              altDescription=''
            />
          </div>
          <CardData className='d-flex flex-column col-sm-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-28 lh-lg-34 fs-xl-48 lh-xl-54 text-grayscale--500 fw-500'>
              Quem pode adiantar o saque-aniversário?
            </h2>
            <p className='fs-16 lh-19 fs-xl-18 lh-xl-22 text-grayscale--500 pb-3'>
              Confira o que é necessário para contratar esse empréstimo:
            </p>
            {DataWithIcon.map((item: dataProps, index: number) => (
              <div key={index} className='d-flex align-items-center mb-4'>
                <div>
                  <OrangeIcon icon={item.icon} size='MD' color={grayscale[500]} className='mr-4' />
                </div>
                <p className='fs-14 lh-16 text-grayscale--500 pb-0 mb-0 fw-600'>{item.description}</p>
              </div>
            ))}
            <UTMLink
              link='SIMULADOR_ANTECIPACAO_FGTS'
              text='Simule sua Antecipação do FGTS'
              btncolor='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none mt-2 text-md-center'
              dobra='dobra_04'
              sectionName='Quem pode adiantar o saque-aniversário?'
            />
          </CardData>
        </div>
      </div>
    </ComoAdiantarSection>
  )
}

export default ComoAdiantar
