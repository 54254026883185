import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const DepoisAutorizarSection = styled.section`
  .btn {
    @media ${device.tablet} {
      max-width: 336px;
      height: 48px;
    }
    @media ${device.desktopLG} {
      max-width: 456px;
    }
  }
`

export const StepNumber = styled.div`
  background: ${orange.extra};
  border-radius: 50%;
  margin-right: 24px;
  min-width: 30px;
  text-align: center;
  padding: 8px;
  font-weight: 700;
  @media ${device.tablet} {
    margin-right: 8px;
  }
`
