import React from 'react'
import { IconWrapper, PopUpContent } from './style'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

export default function ModalMobile ({ closeModal }: { closeModal: (value: boolean) => void }) {
  return (
    <div>
      <PopUpContent>
        <IconWrapper>
          <OrangeIcon size='LG' icon='attention' color='#fff' />
        </IconWrapper>
        <h3 className='fs-32 lh-40 text-center text-grayscale-500'>As datas de depósito mudaram</h3>
        <p className='fs-18 lh-22 text-center text-grayscale--500'>
          Em 2024, uma nova norma do Governo Federal alterou o prazo do depósito do FGTS e os empregadores podem realizar o pagamento <strong> até o 20º dia de cada mês.</strong> A partir da data, seu saldo na Caixa deve ser atualizado até o último dia do mês.
        </p>
        <p className='fw-600 fs-18 lh-22 text-center text-grayscale--500'>Então fique de olho no seu aplicativo e não perca o dia de antecipar seu FGTS.</p>
        <button
          onClick={() => closeModal(false)}
          className='btn btn-orange--extra btn--lg fs-14 fw-600 rounded-2 text-white text-none mt-3'
        >
          Voltar
        </button>
      </PopUpContent>

    </div>
  )
}
