import React from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import ImageWebp from 'src/components/ImageWebp'

import SlideData from '../../assets/data/slideData.json'
import * as S from './style'

interface IDataTypeProps {
  title: string;
  description: string;
  imageName: string;
}

const EntendaParticipacao = () => {
  return (
    <S.Section>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-4'>
            <h2 className='fs-24 lh-30 fs-md-20 lh-md-24 fs-lg-28 lh-lg-32 fs-xl-40 lh-xl-50 text-grayscale--500 fw-500 pb-md-5 pb-lg-3'>
              Entenda como funciona a Antecipação <br />Saque-Aniversário FGTS
            </h2>
          </div>
          <div className='col-12 col-md-8'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
            >
              {SlideData.map((d: IDataTypeProps) => (
                <div key={d.title} className='row align-items-md-center'>
                  <div className='col-12 col-sm-6'>
                    <S.WrapperImage>
                      <ImageWebp
                        pathSrc={d.imageName}
                        altDescription={d.title}
                        arrayNumbers={[ 330, 215, 290, 360, 360 ]}
                        arrayNumbersHeight={[ 238, 160, 217, 260, 260 ]}
                      />
                    </S.WrapperImage>
                  </div>
                  <div className='col-12 col-sm-6'>
                    <S.CardCarousel className='margin-auto'>
                      <p className='fs-16 fs-md-12 fs-xl-18 text-grayscale--500'><strong>{d.title}</strong></p>
                      <span dangerouslySetInnerHTML={{ __html: d.description }} 
                      className='fs-18 fs-md-12 fs-xl-18 text-grayscale--500' />
                    </S.CardCarousel>
                  </div>
                </div>
              ))}
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default EntendaParticipacao
