import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import Hero from './sections/Hero/_index'
import NovaData from './sections/nova-data/_index'
import NaoCorrentista from './sections/NaoCorrentista/_index'
import EntendaParticipacao from './sections/EntendaParticipacao/_index'
import ComoAdiantar from './sections/ComoAdiantar/_index'
import DepoisAutorizar from './sections/DepoisAutorizar/_index'
import EntendaAntecipacaoInter from './sections/SolicitarAntecipacaoInter/_index'
import Vantagens from './sections/Vantagens/_index'
import ValorDoSaque from './sections/ValorDoSaque/_index'
import ComoFunciona from './sections/ComoFunciona/_index'
import FaqSection from './sections/faq/_index'

import QrCodeAntecipacaoFgts from '../antecipacao-fgts/assets/qrcode-fgts.jpg'
import pageContext from './pageContext.json'
import { Wrapper } from './style'

const AntecipacaoFgts = () => {
  const domReady = useDomReady()
  const [ isFormModalOpen, setIsFormModalOpen ] = useState(false)
  const [ isOpen, setIsOpen ] = useState(false)

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_03',
    section_name: 'Cliente Inter tem mais vantagens e aproveita o adiantamento do FGTS. Abra sua conta gratuita!',
    element_action: 'click button',
    element_name: 'Abra sua conta digital',
  })

  const handleFormCloseModal = () => {
    setIsFormModalOpen(!isFormModalOpen)
  }

  const modal = domReady && (
    <Modal isModalOpen={isFormModalOpen } setIsModalOpen={ setIsFormModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm closeModal={handleFormCloseModal} dataLayer={dataLayer} />
    </Modal>
  )

  const openModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Acesse <span class="text-orange--extra">nosso Super App</span> para simular seu saque aniversário do FGTS.'
        instructions='Abra a câmera do seu celular e aponte para o QR Code acima. Você irá direto pra área de saque aniversário FGTS do app. É rápido e fácil!'
        qrCode={QrCodeAntecipacaoFgts}
      />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {modal}
        {openModal}
        <Hero setIsOpen={setIsOpen} />
        <NovaData />
        <NaoCorrentista
          isOpenModal={isFormModalOpen}
          openFormModal={setIsFormModalOpen}
          setDataLayer={setDataLayer}
        />
        <EntendaParticipacao />
        <ComoAdiantar />
        <DepoisAutorizar setIsOpen={setIsOpen} />
        <EntendaAntecipacaoInter />
        <Vantagens />
        <ValorDoSaque />
        <ComoFunciona />
        <FaqSection />
      </Layout>
    </Wrapper>
  )
}

export default AntecipacaoFgts
