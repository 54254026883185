import React, { useState } from 'react'
import { Section } from './style'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import ModalMobile from '../../modals/_ModalMobile'
import PopUpDesktop from '../../modals/_PopupDesktop'
import useWidth from 'src/hooks/window/useWidth'
import Popup from 'src/components/Popup'

const NovaData = () => {
  const domReady = useDomReady()
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isOpen, setIsOpen ] = useState(false)

  const dataLayer: IDataLayerParams = {
    section: 'dobra_02',
    section_name: 'Nova data de depósito!',
    element_action: 'click button',
    element_name: 'Saiba mais',
  }

  const modal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <ModalMobile closeModal={setIsOpen} />
    </Modal>
  )

  const popup = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <Popup
        closeModal={() => setIsOpen(false)}
        pageName='antecipacao-fgts' child={<PopUpDesktop closeModal={setIsOpen} />}
      />
    </Modal>
  )

  return (
    <Section>
      {width < 768 ? modal : popup}
      <div className='container'>
        <div className='row justify-content-end align-items-center'>
          <div className='col-12 col-md-8 col-lg-9 col-xl-10'>
            <h2 className='fs-24 lh-30 fs-lg-28 lh-lg-32 fs-xl-40 lg-xl-50 text-white pb-2 fw-500'>
              Nova data de depósito!
            </h2>
            <p className='fs-14 lh-16 fs-lg-16 fw-600 fs-xl-20 lh-xl-28 text-white mb-md-0'>
              Agora, empresas têm até o dia 20 de cada mês para realizar o depósito do seu FGTS.
            </p>
          </div>
          <div className='col-12 col-md-4 col-lg-3 col-xl-2'>
            <button
              onClick={() => {
                sendDatalayerEvent(dataLayer)
                setIsOpen(true)
              }}
              className='btn btn-orange--extra btn--lg fs-14 fw-600 rounded-2 mx-auto text-white text-none mt-2 w-100'
            >
              Saiba mais
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default NovaData
