import React from 'react'
import FAQ from 'src/components/StructuredData/FAQ'

import { FAQSection } from './style'

import pageContext from '../../pageContext.json'

const FaqSection = () => {
  return (
    <FAQSection id='perguntas-frequentes' className='bg-white py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-4'>
            <h3 className='fs-24 lh-28 fs-md-28 lh-md-32 fs-lg-40 lh-lg-50 text-grayscale--500 text-center fw-500'>
              Perguntas Frequentes sobre a Antecipação do <span className='d-xl-block'>Saque-Aniversário FGTS</span>
            </h3>
          </div>
          <FAQ
            columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            id='cartoes'
            search
            styles='summary-content px-0'
            data={pageContext.structuredData.faq}
          />
        </div>
      </div>
    </FAQSection>
  )
}

export default FaqSection
