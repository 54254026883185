import { grayscale, orange } from 'src/styles/colors'
import { sand } from 'src/styles/newColors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: ${sand};

  .margin-auto {
    margin-left: auto;
    margin-right: auto;
  }
`
export const Steps = styled.div`
  border: 2px solid ${orange.extra};
  border-radius: 50%;
  padding: 1px 14px;
  font-size: 22px;
  font-weight: 700;
  width: fit-content;
  color: ${grayscale[500]};
  margin-bottom: 20px;
`

export const WrapperImage = styled.div`
  display: flex;
  justify-content: center;
`
