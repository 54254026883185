import { breakpoints, device } from 'src/styles/breakpoints';
import { grayscale, orange, red } from 'src/styles/colors';
import { orange as newOrange, sand } from 'src/styles/newColors';
import styled from 'styled-components';

type StyledInputValue = {
  inputActive: boolean;
};

export const ContentHeader = styled.section`
  background-color: ${sand};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 447px;
  overflow: hidden;
  padding-bottom: 0 !important;

  @media ${device.tablet} {
      padding: 48px 0 !important;
    }

    @media ${device.desktopLG} {
      padding: 64px 0 !important;
    }

    @media ${device.desktopXL} {
      padding: 96px 0 !important;
    }

  h1, .text-orange--dark {
    color: ${newOrange.dark}
  }

  .gatsby-image-wrapper {
    max-width: 456px;
    float: right;
  }

  .btn {
    @media ${device.desktopLG} {
      max-width: 456px;
    }
  }

  .cifra-fgts {
    @media ${device.desktopLG} {
      margin-left: 22px;
    }
  }
`
export const SimulatorCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.desktopXL} {
      align-items: flex-start;
    }

   .simulator-box {
    background-color: white;
    border-radius: 32px;
    width: calc(100% - 70px);
    height: 70%;
    padding: 25px;
    margin-top: 45px;

    @media ${device.tablet} {
      width: 300px;
    }

    @media ${device.desktopLG} {
      width: 350px;
    }

    @media ${device.desktopXL} {
      width: 450px;
      padding: 32px;
      align-items: flex-start;
      margin-left: 45px;
    }
   }
`

export const Input = styled.input<StyledInputValue>`
  font-family: "Sora";
  font-size: 28px;
  line-height: 40px;
  font-weight: 400;
  color: ${({ inputActive }: StyledInputValue) => inputActive ? grayscale[500] : grayscale[300]};
  border: none;
  width: 100%;

  &:focus {
    outline: none;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 36px;
    line-height: 50px;
  }
`

export const ModalLink = styled.div`
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  color: ${orange.extra};
`

export const Error = styled.p`
  font-size: 12px;
  text-align: center;
  margin-bottom: -4px;
  margin-top: -16px;
  color: ${red[500]};
`

export const SimulatorDecoration = styled.div`
  background-color: ${orange.extra};
  width: 100%;
  height: 320px;
  outline: 2px white solid;
  outline-offset: -20px;
  border-top-right-radius: 32px;
  border-top-left-radius: 32px;
  position: relative;
  z-index: 1;

  @media ${device.tablet} {
    overflow: hidden;
    border-bottom-left-radius: 32px;
    border-top-right-radius: 0;
    height: 360px;
    width: calc(100% + 60px);
  }

  @media ${device.desktopLG} {
    width: calc(100% + 80px);
    border-bottom-left-radius: 60px;
    border-top-left-radius: 60px;
  }

  @media ${device.desktopXL} {
    width: 150%;
    height: 380px;
  }
`

export const BottomDecoration = styled.div`
  height: 20px;
  float: inline-end;
  width: calc(100% - 36px);
  position: absolute;
  z-index: 2;
  bottom: 0;
  background-color: ${orange.extra};
  margin: 0 18px;
  border-right: 2px white solid;
  border-left: 2px white solid;

  @media ${device.tablet} {
    display: none;
  }
`
