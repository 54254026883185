import React from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import ImageWebp from 'src/components/ImageWebp'

import data from '../../assets/data/antecipationSteps.json'
import * as S from './style'

interface IDataTypeProps {
  text: string;
  image: string;
  alt: string;
}

const EntendaAntecipacaoInter = () => {
  return (
    <S.Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-md-28 lh-md-32 fs-xl-48 lh-xl-54 text-grayscale--500 fw-500 text-center'>
              Entenda como solicitar a antecipação no Inter
            </h2>
          </div>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 3 }}
              lg={{ items: 3 }}
              xl={{ items: 3 }}
            >
              {data.map((d: IDataTypeProps, index: number) => (
                <div key={d.text} className='row'>
                  <div className='col-12 text-center d-flex flex-column align-items-center'>
                    <S.Steps>
                      {index + 1}
                    </S.Steps>
                    <h3 className='fs-20 fs-md-14 fs-lg-20 text-grayscale--500 fw-500' style={{ width: '90%' }}>{d.text}</h3>
                    <S.WrapperImage>
                      <ImageWebp
                        pathSrc={d.image}
                        altDescription={d.alt}
                        arrayNumbers={[ 210 ]}
                        arrayNumbersHeight={[ 400 ]}
                      />
                    </S.WrapperImage>
                  </div>
                </div>
              ))}
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default EntendaAntecipacaoInter
