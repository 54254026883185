import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const ComoAdiantarSection = styled.section`
  @media ${device.tablet} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/768-dobra4-fgts/image.webp');
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media ${device.desktopLG} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/1024-dobra4-fgts/image.webp');
  }
  
  @media ${device.desktopXL} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/1440-dobra4-fgts/image.webp');
  }

  .btn {
    @media ${device.tablet} {
      max-width: 336px;
      height: 48px;
    }
    @media ${device.desktopLG} {
      max-width: 456px;
    }
  }
`

export const CardData = styled.div`
  @media ${device.tablet} {
    background-color: white;
    border-radius: 16px;
    padding: 32px 24px;
  }
`
