import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { BottomDecoration, ContentHeader, Error, Input, ModalLink, SimulatorCard, SimulatorDecoration } from './style'

type HeroProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

function floatToCurrency (floatNumber: number) {
  return floatNumber.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

function currencyToFloat (source: string, currencyNumber: string) {
  const result =
    currencyNumber === ''
      ? 0
      : parseInt(currencyNumber.replace(/\D/g, '')) / 100
  return source === 'range' ? result * 100 : result
}

export const MIN_SIMULATION_VALUE = 100

const Hero = ({ setIsOpen }: HeroProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ value, setValue ] = useState(0)
  const [ inputActive, setInputActive ] = useState(false)

  function changeInput (evt: { target: { name: string; value: string } }) {
    if (!inputActive) {
      setInputActive(true)
    }

    const inputName = evt.target.name
    const inputValue = evt.target.value
    const insertValue = currencyToFloat(inputName, inputValue)
    setValue(insertValue)
  }

  return (
    <ContentHeader>
      <div className='container'>
        <div className='row justify-content-center justify-content-md-between align-items-center'>
          <div className='col-12 col-md-6'>
            <h1 className='fs-24 lh-30 fs-md-28 lh-md-34 fs-lg-40 lh-lg-48 fs-xl-48 lh-xl-54 mb-4 px-0 px-md-auto text-orange--dark fw-500'>
              Antecipação<br /> Saque-Aniversário <br />FGTS a partir de R$100
            </h1>
            <p className='fs-14 lh-20 fs-lg-16 lh-lg-20 fs-xl-20 lh-xl-28 mb-4 pr-md-0 text-orange--dark'>
              Antecipa aí seu FGTS e libere um dinheiro que já é seu. Receba agora até 15 anos do seu saque-aniversário sem parcelas mensais.
            </p>
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <SimulatorDecoration>
              <BottomDecoration />
              <SimulatorCard>
                <div className='simulator-box'>
                  <p className='fs-12 lh-20 fs-lg-16 text-grayscale--400 fw-600 text-center'>Qual é o seu saldo no FGTS?</p>
                  <div className='d-flex align-items-center justify-content-center'>
                    <span className='fs-28 fs-lg-36 lh-50 fw-400 mb-0 text-grayscale--500 d-block cifra-fgts'>
                      R${' '}
                    </span>
                    <Input
                      type='text'
                      onChange={(evt: ChangeEvent<HTMLInputElement>) => changeInput(evt)}
                      value={floatToCurrency(value)}
                      inputActive={inputActive}
                      maxLength={13}
                    />
                  </div>
                  <hr className='mt-0 mb-3' />
                  { value < MIN_SIMULATION_VALUE && inputActive ? <Error>valor mínimo R${MIN_SIMULATION_VALUE},00</Error> : '' }
                  <UTMLink
                    customParameter={`saldo=${value.toString().replace('.', ',')}`}
                    link='SIMULADOR_ANTECIPACAO_FGTS'
                    text='Simular minha antecipação'
                    btncolor={`${value < MIN_SIMULATION_VALUE && 'disabled'} fs-12 fs-md-14 btn text-white btn--lg btn-orange--extra text-none rounded-3 fw-700 my-md-3`}
                    sectionName='Receba sua Antecipação - Saque-Aniversário FGTS!'
                    dobra='dobra_01'
                  />
                  <ModalLink>
                    <a
                      className='d-block d-md-none fs-10 lh-16 fw-700 mt-4 text-orange--extra mt-4'
                      href='https://intergo.app/3c628462'
                      target='_blank'
                      rel='noreferrer'
                      onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: 'Receba sua Antecipação Saque-Aniversário FGTS!',
                        element_action: 'click button',
                        element_name: 'Já sou correntista, quero simular no app',
                        redirect_url: 'https://intergo.app/3c628462',
                      })
                      }}
                    >
                      Já sou correntista, quero simular no app
                    </a>
                    <a
                      className='d-none d-md-block fs-10 lh-16 fw-700 mt-4 text-orange--extra mt-4'
                      onClick={() => {
                        setIsOpen(true)
                        sendDatalayerEvent({
                          section: 'dobra_01',
                          section_name: 'Receba sua Antecipação Saque-Aniversário FGTS!',
                          element_action: 'click button',
                          element_name: 'Já sou correntista, quero simular no app',
                        })
                      }}
                    >
                      Já sou correntista, quero simular no app
                    </a>
                  </ModalLink>
                </div>
              </SimulatorCard>
            </SimulatorDecoration>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default Hero
