import React from 'react'
import { MIN_SIMULATION_VALUE } from '../Hero/_index'
import ImageWebp from 'src/components/ImageWebp'
import * as S from './style'
import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'

const ValorDoSaque = () => {
  return (
    <S.Section className='py-5'>
      <div className='container my-lg-5'>
        <div className='row align-items-center justify-content-between'>
          <div className='order-1 order-sm-2 col-12 col-sm-6 col-md-5 mb-4 mb-sm-0'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/1440-dobra8-fgts/image.webp'
              altDescription=''
            />
          </div>
          <div className='order-2 order-sm-1 col-12 col-sm-6'>
            <h2 className='fs-24 lh-30 fs-lg-28 lh-lg-32 fs-xl-40 lh-xl-50 text-grayscale--500 mb-4 fw-500'>
              Qual valor de saque-aniversário do FGTS pode ser antecipado?
            </h2>
            <p className='fs-14 lg-16 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 fs-xl-24 lh-xl-30 text-grayscale--500 mb-3'>
              Aqui no Inter, a partir de um saldo de R${MIN_SIMULATION_VALUE} você já pode realizar a sua antecipação.
            </p>
            <UTMLink
              style={{ width: '100%', maxWidth: '100%' }}
              link='SIMULADOR_ANTECIPACAO_FGTS'
              text='Simule sua Antecipação do FGTS'
              btncolor='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none mt-3 text-md-center'
              dobra='dobra_08'
              sectionName='Qual valor de saque aniversário do FGTS pode ser antecipado?'
            />
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default ValorDoSaque
