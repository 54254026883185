import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { orange } from 'src/styles/newColors'
import { CardContainer } from './style'
import AdvantagesJson from '../../assets/data/Advantages.json'

interface IAdvantagesTypes {
  icon: string;
  text: string;
}

const Vantagens = () => {
  return (
    <section className='py-5'>
      <div className='container my-lg-5'>
        <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-48 fs-xl-48 lh-xl-54 text-grayscale--500 fw-500 text-center'>
          Quais são as vantagens da Antecipação <span className='d-xl-block'>Saque-Aniversário FGTS?</span>
        </h2>
        <div className='row'>
          {AdvantagesJson.map((advantages: IAdvantagesTypes) => (
            <div className='col-12 col-sm-6 mt-4' key={advantages.icon}>
              <CardContainer>
                <div className='d-block d-lg-none'>
                  <OrangeIcon icon={advantages.icon} size={advantages.icon === 'smartphones' ? 'LD' : 'LG'} color={orange.clay} />
                </div>
                <div className='d-none d-lg-block'>
                  <OrangeIcon icon={advantages.icon} size='XL' color={orange.clay} />
                </div>
                <p className='fs-14 lh-17 fs-md-12 lh-md-16 fs-lg-16 lh-lg-19 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: advantages.text }} />
              </CardContainer>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Vantagens
