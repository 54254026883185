import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const CardContainer = styled.div`
  background: ${grayscale[100]};
  padding: 30px 16px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  min-height: 132px;

  p {
    margin-left: 16px;
  }

  circle {
    stroke-width: 2.5;
  }

  rect {
    stroke-width: 2.5;
  }

  path {
    stroke-width: 2.5;
  }
`
