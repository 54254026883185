import React from 'react'
import { IconWrapper, ModalContent, Content } from './style'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

export default function ModalMobile ({ closeModal }: { closeModal: (value: boolean) => void }) {
  return (
    <div style={{ overflow: 'hidden', height: '100vh' }}>
      <ModalContent>
        <Content>
          <IconWrapper>
            <OrangeIcon size='LG' icon='attention' color='#fff' />
          </IconWrapper>
          <h3 className='fs-24 lh-28 text-center text-white'>As datas de depósito mudaram</h3>
          <p className='fs-14 lh-16 text-center text-white'>
            Em 2024, uma nova norma do Governo Federal alterou o prazo do depósito do FGTS e os empregadores podem realizar o pagamento <strong> até o 20º dia de cada mês.</strong> A partir da data, seu saldo na Caixa deve ser atualizado até o último dia do mês.
          </p>
          <p className='fw-600 fs-14 lh-16 text-center text-white'>Então fique de olho no seu aplicativo e não perca o dia de antecipar seu FGTS.</p>
        </Content>
      </ModalContent>
      <button
        style={{ marginTop: -280, marginLeft: 24, marginRight: 24, width: 'calc(100% - 48px)' }}
        onClick={() => closeModal(false)}
        className='btn btn-white btn--lg fs-14 fw-600 rounded-2 text-orange--extra text-none'
      >
        Voltar
      </button>
    </div>
  )
}
