import styled from 'styled-components'
import { orange } from 'src/styles/newColors'

export const Section = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px !important;
  background-color: ${orange.clay};

  button {
    font-size: 14px !important;
    font-weight: 700 !important;
    max-width: 100%;
  }
`
