import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const ComoFuncionaSection = styled.section`
  .btn {
    @media ${device.tablet} {
      max-width: 336px;
      height: 48px;
    }
    @media ${device.desktopLG} {
      max-width: 456px;
    }
  }
`
